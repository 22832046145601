import {text as t} from './text';

export const cashValues = {
    us: {
        numberOfOneDollarBills: { val: 1, label: '$1' }, 
        numberOfFiveDollarBills: { val: 5, label: '$5' }, 
        numberOfTenDollarBills: { val: 10, label: '$10' },  
        numberOfTwentyDollarBills: { val: 20, label: '$20' }, 
        numberOfFiftyDollarBills: { val: 50, label: '$50' }, 
        numberOfHundredDollarBills: { val: 100, label: '$100' }, 
    },
    ugd: {
        numberOfThousandDollarBills: { val: 1000, label: '1,000' }, 
        numberOfTwoThousandDollarBills: { val: 2000, label: '2,000' }, 
        numberOfFiveThousandDollarBills: { val: 5000, label: '5,000' },  
        numberOfTenThousandDollarBills: { val: 10000, label: '10,000' }, 
        numberOfTwentyThousandDollarBills: { val: 20000, label: '20,000' }, 
        numberOfFiftyThousandDollarBills: { val: 50000, label: '50,000' }, 
    }
};

export const kioskDevices = [2, 4, 7, 9];

export const deviceTypes = [
    {id: 1, label: 'PrimePay Kiosk'},
    {id: 2, label: 'GRC Kiosk'},
    {id: 3, label: 'Piggy Bank Deluxe'},
    {id: 4, label: 'GameRoom POS'},
    {id: 5, label: 'Monitor'},
    {id: 6, label: 'Piggy Bank UG'},
    {id: 7, label: 'Bingo Kiosk'},
    {id: 8, label: 'PrimePay Kiosk V2'},
    {id: 9, label: 'GRC POS'}
]

export const jackpotConfigs = {
    order: [t.grand, t.major, t.minor, t.mini],
    formats: [ 'currency', 'currency', 'currency', 'currency']
}

export const reportsData = {
    dropdownId: 'selected-report',
    subtypes: {
        performance: [
            {value: 'company-performance', label: t.companyPerformance},
            {value: 'location-performance', label: t.locationPerformance},
            {value: 'game-performance', label: t.gamePerformance},
            {value: 'gameroom-performance', label: t.gameroomPerformance},
            {value: 'kiosk-performance', label: t.kioskPerformance},
            {value: 'cash-on-hand', label: t.cashOnHand},
        ]
    },
}

export const serviceTypes = {
    ManuallyCollected: t.manualCollect,
    Pos: t.pointOfSales,
    PrimePay: t.primePay,
    SweepsKiosk: t.sweepsKiosk,
};

export const serviceTypesEnums = {
    0: t.pointOfSales,
    1: t.manualCollect,
    2: t.primePay,
    3: t.sweepsKiosk,
};

export const timezones = [
    {label: 'Alaska Standard Time (Anchorage, US)', value: 'alaska'},
    {label: 'Central Standard Time (Chicago, US)', value: 'central'},
    {label: 'Eastern Standard Time (New York City, US)', value: 'eastern'},
    {label: 'Hawaii-Aleutian Standard Time (Honolulu, Pacific)', value: 'hawaii'},
    {label: 'Mountain Standard Time (Denver, US)', value: 'mountain'},
    {label: 'Central Standard Time (Chicago, US)', value: 'central'},
    {label: 'Pacific Standard Time (Log Angeles, US)', value: 'pacific'},
];

export const roles = { 
    list:  [
        {id: 4, role: t.administrator, rank: 1},
        {id: 3, role: t.superUser, rank: 5},
        {id: 2, role: t.owner, rank: 10},
        {id: 5, role: t.locationManager, rank: 10}, 
        {id: 6, role: t.pullTabsVendor, rank: 20}, 
    ],
    labels: {
        4: t.administrator, 
        3: t.superUser,
        2: t.owner,
        5: t.locationManager,
        6: t.pullTabsVendor
    }
}

export const ranks = {
    admin: 1,
    superuser: 5,
    owner: 10,
    locationManager: 10,
    all: 15,
    pullTabsVendor: 20,
}

export const ranksById = {
    4: 1,
    3: 5,
    2: 10,
    5: 10,
    6: 20,
}

export const viewRanks = {
    '/companies': ranks.superuser,
    '/company/:id': ranks.superuser,
    '/company/:id/users': ranks.superuser,
    '/company/:id/locations': ranks.superuser,
    // '/company/:id/settings': ranks.superuser,
    '/company/:id/services': ranks.superuser,
    '/company/:id/devices': ranks.superuser,
    '/devices': ranks.all,
    '/devices/primepay': ranks.all,
    '/devices/grc-kiosks': ranks.all,
    '/devices/gameroom-pos': ranks.all,
    '/devices/grc-pos': ranks.all,
    '/devices/games': ranks.all,
    '/devices/monitors': ranks.all,
    '/devices/bingo': ranks.all,
    '/device/:id': ranks.all,
    '/device/:id/controls': ranks.owner,
    '/device/:id/reports': ranks.all,
    '/device/:id/reports/:type': ranks.all,
    '/devices/batch-assignment': ranks.all,
    '/locations': ranks.all,
    '/location/:id': ranks.all,
    '/location/:id/devices': ranks.all,
    '/location/:id/reports': ranks.all,
    '/location/:id/reports/:type': ranks.all,
    '/location/:id/settings': ranks.all,
    '/location/:id/services': ranks.all,
    '/location/:id/players': ranks.all,
    '/permissions': ranks.owner,
    '/permissions/users': ranks.owner,
    '/pull-tabs': [ranks.admin, ranks.pullTabsVendor],
    '/reports': ranks.all,
    '/reports/:type': ranks.all,
    '/users': ranks.all,
    '/user/:id': ranks.all,
    '/user/:id/companies': ranks.owner,
    '/user/:id/password': ranks.owner,
    '/user/:id/permissions': ranks.all,
    '/api-logs': ranks.admin,
    '/devOnly': ranks.admin,
    '/delete-accounts': ranks.admin,
    '/manifests': ranks.admin,
    '/manifest/:id': ranks.admin,
    '/sources': ranks.admin,
    '/source/:id': ranks.admin,

    editCompany: {section: 'Companies', permission: 'Edit'},
    editDevice: {section: 'Devices', permission: 'Edit'},
    batchAssignment: {section: 'Devices', permission: 'Edit'},
    editLocation: {section: 'Locations', permission: 'Edit'},
    editPermission: {section: 'Permissions', permission: 'Edit'},
    editUser: {section: 'Users', permission: 'Edit'},

    editLocationShares: {section: 'Locations', permission: 'Edit'},
    editLicenseRate: {section: 'DevOnly', permission: 'Edit'},
    editManifest: {section: 'DevOnly', permission: 'Edit'},
    editSource: {section: 'DevOnly', permission: 'Edit'}
}

export const vendorsList = [
    {value: 'PTW', label: 'Pull To Win'},
    {value: 'WSM', label: 'Milk Caps'}, // Full name Wisconsin Souvenir Milk Cap Pull Tab
]