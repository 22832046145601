import { text as t } from 'shared/text';

export const devicesLayout = {
    id: 'devices',
    
    tableHeaders: {
        id: t.deviceId,
        machineSerialNumber: t.serialNumber,
        locationName: t.locationName,
        terminalTypeName: t.type,
        transactions: t.transactions,
    },

    sizes: {
        id: 'mid',
        machineSerialNumber: 'mid',
        locationName: 'mid',
        terminalTypeName: 'mid',
        transactions: 'mid',
    },

    layouts: {
        id: 'text-left',
        machineSerialNumber: 'text-left',
        locationName: 'text-left',
        terminalTypeName: 'text-left',
    },

    navigation: {
        id: {
            label: 'id',
            data: [
                {type: 'text', val: 'device'},
                {type: 'prop', val: 'id'},
            ]
        },
        locationName: {
            label: 'locationName',
            data: [
                {type: 'text', val: 'location'},
                {type: 'prop', val: 'locationId'},
            ]
        },
        transactions: {
            icon: 'list-check',
            data: [
                {type: 'text', val: 'device'},
                {type: 'prop', val: 'transactions.id'},
                {type: 'text', val: 'reports/transactions'},
            ]
        }
    },

    hideSortArrows: {
        transactions: true,
    },
}
