import { text as t } from 'shared/text'

export const gamePerformanceReportsLayout = {    

    defaultSort: {
        orderBy: 'profit',
        desc: true
    },

    hasDateRange: true,

    tableHeaders: {
        terminalId: t.terminalId,
        locationName: t.locationName,
        terminalTypeDescription: t.deviceType,
        gameTypePercentage: t.payRate,
        totalPlays: t.plays,
        totalCashIn: t.cashIn,
        totalVTP: t.vtp,
        totalWins: t.totalWins,
        profit: t.profit,
        rtp: t.rtp,
        averageBet: t.averageBet,
        averageWin: t.averageWin,
        highestWin: t.highestWin,
        hitFrequencyPercentage: t.hitFrequencyPercentage,
    },

    sizes: {
        terminalId: 'large',
        locationName: 'large',
        terminalTypeDescription: 'large',
        gameTypePercentage: 'mid',
        totalPlays: 'mid',
        totalCashIn: 'large',
        totalVTP: 'large',
        totalWins: 'large',
        profit: 'large',
        rtp: 'mid',
        averageBet: 'mid',
        averageWin: 'mid',
        highestWin: 'mid',
        hitFrequencyPercentage: 'large',
    },

    layouts: {
        terminalId: 'text-left',
        locationName: 'text-left',
        terminalTypeDescription: 'text-left',
        gameTypePercentage: 'text-right',
        totalPlays: 'text-right',
        totalCashIn: 'text-right',
        totalVTP: 'text-right',
        totalWins: 'text-right',
        profit: 'text-right',
        rtp: 'text-right',
        averageBet: 'text-right',
        averageWin: 'text-right',
        highestWin: 'text-right',
        hitFrequencyPercentage: 'text-right right-pad',
    },

    formats: {
        gameTypePercentage: 'percentage-whole',
        totalPlays: 'number',
        totalCashIn: 'currency-whole',
        totalVTP: 'currency-whole',
        totalWins: 'currency-whole',
        profit: 'currency-whole',
        rtp: 'percentage-whole',
        averageBet: 'currency',
        averageWin: 'currency',
        highestWin: 'currency-whole',
        hitFrequencyPercentage: 'percentage-whole',
    },

    navigation: {
        terminalId: {
            label: 'terminalId',
            data: [
                {type: 'text', val: 'device'},
                {type: 'prop', val: 'terminalId'},
                {type: 'text', val: 'reports/games'},
            ]
        },
        locationName: {
            label: 'locationName',
            data: [
                {type: 'text', val: 'location'},
                {type: 'prop', val: 'locationId'}, 
            ]
        }
    },

    // subtotals: {
    //     groupBy: 'all',
    //     fields: {
    //         terminalId: 'header',
    //         terminalTypeDescription: 'none',
    //         totalPlays: 'total',
    //         totalCashIn: 'total',
    //         totalVTP: 'total',
    //         totalWins: 'total',
    //         profit: 'total',
    //         rtp: {
    //             first: {column:'totalWins', type: 'total'},
    //              calc: 'divide',
    //              second: {column:'totalVTP', type: 'total'}
    //          },
    //         averageBet: {
    //             first: {column:'totalVTP', type: 'total'},
    //             calc: 'divide',
    //             second: {column:'totalPlays', type: 'total'},
    //         },
    //         averageWin: {
    //             first: {column:'totalWins', type: 'total'},
    //             calc: 'divide',
    //             second: {column:'numberOfWins', type: 'total'}
    //         },
    //         highestWin:'max',
    //         hitFrequencyPercentage: {
    //             first: {column:'numberOfWins', type: 'total'},
    //             calc: 'divide',
    //             second: {column:'totalPlays', type: 'total'}
    //         }
    //     },
    //     formats: {
    //         totalPlays: 'number',
    //         totalCashIn: 'currency-whole',
    //         totalVTP: 'currency-whole',
    //         totalWins: 'currency-whole',
    //         profit: 'currency-whole',
    //         rtp: 'percentage-decimal-whole',
    //         averageBet: 'currency',
    //         averageWin: 'currency',
    //         highestWin: 'currency-whole',
    //         hitFrequencyPercentage: 'percentage-decimal-whole',
    //     }
    // }
}
