import './cashOnHandReport.scss';
import { useEffect, useRef, useState } from 'react';
import { cashOnHandReportLayout } from './cashOnHandReportLayout';
import { deepCopy, getDefaultDateRange, 
         getAPIDates, toDateSimple } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import * as e from 'shared/excel/excel';
import useInputs from 'components/hooks/Inputs/useInputs';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import Dropdown from 'components/Dropdown/Dropdown';
import TableListed from 'components/Tables/TableListed';
const tableId = 'cashOnHandReport';
const companyId = tableId + '-companyId';

const CashOnHandReport = () => {
    const { updateDropdown, dropdownOriginals } = useInputs();
    const { getURLParams, updateDropdowns, updateTable,
            configureExcelDates, configureExcelReport } = useTables();
    const { callAPI, callAPIGet } = useUtils();
    const [ layout, setLayout ] = useState(cashOnHandReportLayout);
    const [ companies, setCompanies ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ currentRange, setCurrentRange ] = useState();
    const [ tableRecords, setTableRecords ] = useState();
    const [ masked, setMasked ] = useState(true);    
    const runOnce = useRef();
    
    useEffect(() => {
        if (runOnce.current) {return};
        runOnce.current = true;
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        setCurrentRange(range);
        getReportData(range);
        callAPI('companies', getCompaniesCallback);
    }, []);

    useEffect(() => {
        const urlParams = getURLParams(tableId);
        if (urlParams.dropdowns) {
            const sorts = urlParams.dropdowns?.[companyId];
            sorts && updateDropdown(companyId, sorts.value, sorts.label);
        }
    }, [companies])

    const getReportData = (range) => {
        setMasked(true);
        updateTable('dates', tableId, range);
        const newLayout = deepCopy(layout);
        newLayout.tableHeaders.startCashOnHand = toDateSimple(range[0]);
        newLayout.tableHeaders.endCashOnHand = toDateSimple(range[1]);
        setLayout(newLayout)
        const params = getAPIDates(range);
        callAPIGet('report-CashOnHand', cashOnHandReportCallback, params);
    }
    
    const cashOnHandReportCallback = (data) => {
        setTableRecords(data?.lineItems || []);
        setMasked(false);
    }

    const getCompaniesCallback = (data) => {
        setCompanies(data?.companyList || []);
    }

    const onCompanySelect = (data) => {
        data.searchData = {
            dropdownProp: 'label',
            recordProp: 'companyName'
        }
        updateDropdowns(tableId, companyId, data);
    }

    const onDateRangePick = (range) => {
        getReportData(range);
        setCurrentRange(range);
    }

    const onExportToExcel = () => {
        const title = `${t.cashOnHand} ${t.report}`
        const dateInfo = configureExcelDates(tableId, currentRange, {
            title: title,
            columns: ['companyName', 'startDate', 'endDate'],
            data:[{companyName: dropdownOriginals?.[companyId] || t.all}],
        });
        layout.formats.locationName = 'text-wrap';
        const [tableInfo, sizes] = configureExcelReport(tableId, t.devices, layout);
        e.exportToFile(title, [dateInfo, tableInfo], sizes);
    }

    return (<>
        <TableListed
            id={tableId}
            data={tableRecords}
            layout={layout}
            masked={masked}
            searchBarContent={<>
                <Dropdown
                    id={companyId}
                    classes='quarter'
                    label={t.companyName}
                    hideErrors={true}
                    masked={masked}
                    data={companies}
                    nullable={t.all}
                    callback={onCompanySelect}
                />
                <DateRangePicker
                    id='dateRange'
                    classes='quarter'
                    label={t.dateRange} 
                    disabled={masked}
                    defaultVal={defaultDates}  
                    callback={onDateRangePick}
                />
                <Button
                    classes='green sixth match-labeled-input'
                    disabled={masked}
                    onClick={onExportToExcel}
                >{t.exportToExcel}</Button>
            </>}
        />
    </>)
}

export default CashOnHandReport;
