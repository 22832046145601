import { text as t } from 'shared/text';

export const devicePullTabsBoxesLayout = {
    defaultSort: {
        orderBy: 'importedOn',
        desc: true,
    },

    tableHeaders: {
        serialNumber: t.boxNumber,
        vendor: t.vendor,
        redeemedAmount: t.redeemed,
        outstandingAmount: t.outstanding,
        totalAmount: t.total,
        importedOn: t.importedOn,
    },

    sizes: {
        serialNumber: 'large',
        vendor: 'large',
        redeemedAmount: 'large',
        outstandingAmount: 'large',
        totalAmount: 'large',
        importedOn: 'large',
    },

    layouts: {
        vendor: 'text-right',
        redeemedAmount: 'text-right',
        outstandingAmount: 'text-right',
        totalAmount: 'text-right',
        importedOn: 'text-left',
    },

    formats: {
        redeemedAmount: 'currency-decimal',
        outstandingAmount: 'currency-decimal',
        totalAmount: 'currency-decimal',
        importedOn: 'date-standard',
    },

    subtotals: {
        groupBy: 'all',
        fields: {
            gameTypeName: 'header',
            redeemedAmount: 'total',
            outstandingAmount: 'total',
            totalAmount: 'total',
        },
        formats: {
            redeemedAmount: 'currency-decimal',
            outstandingAmount: 'currency-decimal',
            totalAmount: 'currency-decimal',
        }
    }
}