import './devicePullTabsBoxes.scss';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { devicePullTabsBoxesLayout as layout } from './DevicePullTabsBoxesLayout';
import { toDateStandard } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import * as e from 'shared/excel/excel';
import useProfile from 'hooks/Profile/useProfile';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import TableListed from 'components/Tables/TableListed';

const DevicePullTabsBoxes = () => {
    const { id } = useParams();
    const { setHeader, updateDeviceBreadcrumbs } = useProfile();
    const { configureExcelDates, configureExcelReport } = useTables();
    const { callAPIGet } = useUtils();
    const [ pageData, setPageData ] = useState();
    const [ tableRecords, setTableRecords ] = useState();
    const [ masked, setMasked ] = useState(true);    
    const [ timeStamp, setTimeStamp ] = useState();
    const runOnce = useRef();
    const header = useRef();
    const deviceType = useRef();
    const tableId = 'device-games-' + id;

    useEffect(() => {
        if (runOnce.current) {return};
        runOnce.current = true;
        callAPIGet('deviceDetails', deviceDetailsCallback, {terminalId: id});
        callAPIGet('report-DevicePullTabsBoxes', devicePullTabsBoxesCallback, {terminalId: id});
    }, []);

    const deviceDetailsCallback = (data) => {
        setPageData(data);
        updateDeviceBreadcrumbs(data);
        const type = data.terminalType;
        deviceType.current = type.id;
        header.current = type.name + ': ' + id
        setHeader(header.current);
    }

    const devicePullTabsBoxesCallback = (data) => {
        setMasked(false);
        setTimeStamp(toDateStandard(new Date));
        setTableRecords(data?.items || []);
    }

    const onExportToExcel = () => {
        const title = `${header.current} ${t.games} ${t.report}`;
        const dateInfo = configureExcelDates(tableId, null, {
            title: title,
            columns: ['locationName', 'timeStamp'],
            data:[{
                locationName: pageData?.location?.label,
                timeStamp: timeStamp
            }],
            configs: {
                formats: {
                    locationName: 'text-wrap',
                    timeStamp: 'text-wrap'
                },
            }
        });
        const [tableInfo, sizes] = configureExcelReport(tableId, t.locations, layout);
        e.exportToFile(title, [dateInfo, tableInfo], sizes);
    }

    return (<TableListed
            id={tableId}
            classes='full'
            data={tableRecords}
            layout={layout}
            masked={masked}
            searchBarContent={
                <Button
                    classes='green third match-labeled-input'
                    disabled={masked}
                    onClick={onExportToExcel}
                >{t.exportToExcel}</Button>
            }
        />
    )
}

export default DevicePullTabsBoxes;
