import './locationSettings.scss';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { deepCopy, isValid } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import useAuth from 'hooks/Auth/useAuth';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Card from 'app-components/Cards/Card/Card';
import Checkbox from 'components/Checkbox/Checkbox';
import Dropdown from 'components/Dropdown/Dropdown';
import Input from 'components/Input/Input';
import Page from 'app-components/Page/Page';

const LocationSettings = () => {
    const { id }  = useParams();
    const { getCountry } = useAuth();
    const { inputValues, changeInputValues, 
            changeInputErrors, clearAllInputs,
            updateNestedInputData } = useInputs();
    const { locationTabs, updateLocationBreadcrumbs, 
            updateLocationHeader } = useProfile();
    const { callAPI, callAPIGet,
            hasRank, updateNotice,
            updateLocationTabs } = useUtils();
    const [ pageData, setPageData ] = useState();
    const [ alerts, setAlerts ] = useState();
    const [ alertsContacts, setAlertsContacts ] = useState();
    const [ alertsFirstIndex, setAlertsFirstIndex ] = useState(0); 
    const [ alertsSecondIndex, setAlertsSecondIndex ] = useState(0);
    const [ masked, setMasked ] = useState(true);    
    const permitted = hasRank('owner', true);
    const runOnce = useRef(false);
    const setOnce = useRef(false);
    const deviceTypes = useRef();
    const country = useRef(getCountry());

    useEffect(() => {
        if (runOnce.current && pageData?.id === id) {return};
        runOnce.current = true;
        callAPI('locationDetails', locationDetailsCallback, {id: id});
        callAPIGet('locationAlertsGet', locationAlertsGetCallback, {locationId: id})
        callAPIGet('locationAlertsContacts', locationAlertsContactsCallback, {locationId: id})
        return () => {clearAllInputs()};
    }, []);

    useEffect(()=> {
        if (alerts?.length > 0 && alertsContacts && !setOnce.current) {
            setOnce.current = true;
            const alertsInputs = {};       
            let prop = '';
            alerts.forEach((alert)=> {
                const type = alert.kioskAlertTypeEnum;
                if (type === 1) {
                    prop = 'lowBills';
                    const contacts = alert.contacts;
                    contacts.forEach((contact)=>{
                        const pre = contact.kioskAlertContactType === 0 ? 'first.' : 'second.';
                        Object.entries(contact).forEach(([propKey, value]) => {
                            alertsInputs['lowBills-' + pre + propKey] = value;
                            const updatedContact = alertsContacts.find(c => c.id == contact.id);
                            if (updatedContact) {
                                Object.entries(updatedContact).forEach(([propKey, value]) => {
                                    alertsInputs['lowBills-' + pre + propKey] = value;
                                });
                            }
                        });
                    });
                } else if (type === 2) {
                    prop = 'tilts'
                } else if (type === 3) {
                    prop = 'primepay'
                }
                setInputs(prop,  alert, alertsInputs);


            });
            const dropdownList = {
                ['lowBills-first.id']: {list: 0,  labelProp: 'label', appendProp: 'name'},
                ['lowBills-second.id']: {list: 0, labelProp: 'label', appendProp: 'name'}
            }
            const update = updateNestedInputData(alertsInputs, dropdownList, [alertsContacts]);
            update && setMasked(false);
        }
    }, [alerts, alertsContacts])

    const setInputs = (prop, alert, obj) => {
        const keys = ['active', 'lowRecycler10', 'lowRecycler20'];     
        keys.forEach((key) => {
            obj[prop + '-' + key] = alert[key];
        })
    }

    useEffect(() => {
        changeInputErrors(null);
    }, [inputValues])

    useEffect(() => {
        if (!isValid(alertsFirstIndex) || !alertsContacts) {return}
        const newValues = deepCopy(inputValues);
        const newContact = alertsContacts[alertsFirstIndex]; 
        newValues['lowBills-first.phone'] = newContact.phone;
        newValues['lowBills-first.email'] = newContact.email;
        newValues['lowBills-first.name'] = newContact.name;
        newValues['lowBills-first.contactId'] = alertsFirstIndex;
        newValues['lowBills-first.id'] = newContact.id;
        if (!newContact.id || !newContact.phone) {
            newValues['lowBills-first.textAlert'] = false;
        }
        if (!newContact.id || !newContact.email) {
            newValues['lowBills-first.emailAlert'] = false;
        }
        changeInputValues(newValues);
    }, [alertsFirstIndex])

    useEffect(() => {
        if (!isValid(alertsSecondIndex) || !alertsContacts) {return}
        const newValues = deepCopy(inputValues);
        const second = alertsContacts[alertsSecondIndex];
        newValues['lowBills-second.phone'] = second.phone;
        newValues['lowBills-second.email'] = second.email;
        newValues['lowBills-second.name'] = second.name;
        newValues['lowBills-second.contactId'] = alertsSecondIndex;
        newValues['lowBills-second.id'] = second.id;
        if (!second.id || !second.phone) {
            newValues['lowBills-second.textAlert'] = false;
        }
        if (!second.id || !second.email) {
            newValues['lowBills-second.emailAlert'] = false;
        }
        changeInputValues(newValues);
    }, [alertsSecondIndex])

    const locationDetailsCallback = (data) => {
        if (data?.isSuccessful) {
            const details = data?.locationDetails;
            const types = data?.kioskTypes;
            deviceTypes.current = types;
            updateLocationTabs(types);
            setPageData(details);
            updateLocationHeader(details);
            updateLocationBreadcrumbs(details);
        } else {
            setPageData({})
        }
    }

    const locationAlertsGetCallback = (data) => {
        setAlerts(data?.kioskAlerts || []);
    }

    const locationAlertsContactsCallback = (data) => {
        setAlertsContacts(data?.kioskAlertContacts ? data.kioskAlertContacts : []);
    }

    const onDropdownCallback = (data, state) => {
        const index = data.index;
        state === 'first' ? setAlertsFirstIndex(index) : setAlertsSecondIndex(index);
    }

    const onSubmit = (event, setLoading) => {
        setLoading(true);
        changeInputErrors({});
        const lowBills = {kioskAlertTypeEnum: 1};
        const tilts = {kioskAlertTypeEnum: 2};
        const primepay = {kioskAlertTypeEnum: 3};
        const sections = { lowBills, tilts, primepay };

        const indexOrZero = (arr, predicate) => {
            return Math.max(0, arr.findIndex(predicate));
        };
        
        const contacts = [
            {kioskAlertContactType: 0, kioskAlertUserType: indexOrZero(alertsContacts, c => c.id === inputValues['lowBills-first.id'])}, 
            {kioskAlertContactType: 1, kioskAlertUserType: indexOrZero(alertsContacts, c => c.id === inputValues['lowBills-second.id'])}, 
        ];
        const allowedFields = ['emailAlert', 'id', 'name', 'textAlert'];
        Object.entries(inputValues).forEach(([key, value]) => {
            const topParts = key.split('-');
            const [topSection, topField] = topParts;
            const currentSection = sections[topSection];
            if (topField.includes('.')) {
                if (topSection === 'lowBills') {
                    const parts = topField.split('.');
                    const [section, field] = parts;
                    if (!allowedFields.includes(field)) {return}
                    if (section === 'first') {
                        contacts[0][field] = value;
                    } else if (section === 'second') {
                        contacts[1][field] = value;
                    }
                }
            } else {
                if (topField.includes('Recycler')) {
                    currentSection[topField] = parseInt(value);
                } else {
                    currentSection[topField] = value;

                }
            }
        });
        lowBills.contacts = contacts;
        tilts.contacts = contacts;
        primepay.contacts = contacts;
        const newAlerts = [
            lowBills,
            tilts,
            primepay
        ]
        setAlerts(newAlerts)
        const params = {
            locationId: id,
            kioskAlerts: newAlerts
        }
        const props = {event: event, setLoading: setLoading}
        callAPI('locationAlertsSet', locationAlertsSetCallback, params, props);
    }

    const locationAlertsSetCallback = (data, props) => {
        props.setLoading(false);
        updateNotice(data, props);
    }

    return (
        <Page
            subPage={t.settings}
            tabs={locationTabs.tabs}
            contentClasses='grid location-details'
        >
            <Card label={t.alertContacts} classes='full'>
                <Dropdown label={t.primaryAlert} section='lowBills' line='first.id' classes='third' data={alertsContacts} callback={(data)=>{onDropdownCallback(data, 'first')}}
                    labelProp='label' appendProp='name' masked={masked} noPermission={!permitted}/>
                <Checkbox label={t.alertText} section='lowBills' line='first.textAlert' classes={`twelfth`} noPermission={!permitted}
                    disabled={!inputValues?.['lowBills-first.id'] || !inputValues?.['lowBills-first.phone']}/>
                <Input label={t.phoneNumber} section='lowBills' line='first.phone' classes='quarter display-only' placeholder={t.none}
                    type='phone' masked={masked} noPermission={!permitted}/>
                <Checkbox label={t.alertEmail} section='lowBills' line='first.emailAlert' classes={`twelfth`} noPermission={!permitted}
                    disabled={!inputValues?.['lowBills-first.id'] || !inputValues?.['lowBills-first.email']}/>
                <Input label={t.email} section='lowBills' line='first.email' classes='quarter display-only' placeholder={t.none}
                    masked={masked} noPermission={!permitted}/>
                
                <Dropdown label={t.secondaryAlert} section='lowBills' line='second.id' classes='third' data={alertsContacts} callback={(data)=>{onDropdownCallback(data, 'second')}}
                    labelProp='label' appendProp='name' masked={masked}noPermission={!permitted}/>
                <Checkbox label={t.alertText} section='lowBills' line='second.textAlert' classes={`twelfth`} noPermission={!permitted}
                    disabled={!inputValues?.['lowBills-second.id'] || !inputValues?.['lowBills-second.phone']}/>
                <Input label={t.phoneNumber} section='lowBills' line='second.phone' classes='quarter display-only' placeholder={t.none}
                    type='phone' masked={masked} noPermission={!permitted}/>
                <Checkbox label={t.alertEmail} section='lowBills' line='second.emailAlert' classes={`twelfth`} noPermission={!permitted}
                    disabled={!inputValues?.['lowBills-second.id'] || !inputValues?.['lowBills-second.email']}/>
                <Input label={t.email} section='lowBills' line='second.email' classes='quarter display-only' placeholder={t.none}
                    masked={masked} noPermission={!permitted}/>
            </Card>
            <Card label={t.kioskLowBillAlerts} classes='full'>
                <Checkbox label={t.active} section='lowBills' line='active' classes='third' noPermission={!permitted}/>
                <Input label={country.current === 'UG' ? t.alertForTenUGD : t.alertForTen} section='lowBills' line='lowRecycler10' classes='third'
                    type='max100' disabled={!inputValues?.['lowBills-active']} masked={masked} noPermission={!permitted}/>
                <Input label={country.current === 'UG' ? t.alertForTwentyUGD : t.alertForTwenty} section='lowBills' line='lowRecycler20' classes='third'
                    type='max100' disabled={!inputValues?.['lowBills-active']} masked={masked} noPermission={!permitted}/>
            </Card>

            <Card label={t.kioskHardwareAlerts} classes='full location-settings-padding'>
                <div className='full grid'>
                    <Checkbox label={t.active} section='tilts' line='active' classes='third' noPermission={!permitted}/>
                </div>
            </Card>

            <Card label={t.primePayLowBillAlerts} classes='full'>
                <div className='full grid'>
                    <Checkbox label={t.active} section='primepay' line='active' classes='third' noPermission={!permitted}/>
                    <Input label={t.billCount} section='primepay' line='lowRecycler10' classes='third'
                    type='max100' disabled={!inputValues?.['primepay-active']} masked={masked} noPermission={!permitted}/>
                </div>
                {permitted && <Button
                    classes={`green last sixth`}
                    type='submit'
                    onClick={(event, setLoading)=>onSubmit(event, setLoading)}
                    >{t.submit}
                </Button>}
            </Card>
        </Page>
    )
}

export default LocationSettings;
