import { text as t } from 'shared/text'

export const cashOnHandReportLayout = {
    defaultSort: {
        orderBy: 'locationName',
        desc: false,
    },

    tableHeaders: {
        locationName: t.locationName,
        companyName: t.companyName,
        terminalId: t.device,
        deviceType: t.deviceType,
        startCashOnHand: t.cashIn,
        endCashOnHand: t.cashOut
    },

    sizes: {
        locationName: 'xl',
        companyName: 'xl',
        terminalId: 'large',
        deviceType: 'xl',
        startCashOnHand: 'large',
        endCashOnHand: 'large'
    },

    layouts: {
        locationName: 'text-left',
        companyName: 'text-left',
        terminalId: 'text-left',
        deviceType: 'text-left',
        startCashOnHand: 'text-right',
        endCashOnHand: 'text-right'
    },

    formats: {
        startCashOnHand: 'currency-decimal',
        endCashOnHand: 'currency-decimal'
    },

    navigation: {
        locationName: {
            label: 'locationName',
            data: [
                {type: 'text', val: 'location'},
                {type: 'prop', val: 'locationId'}
            ]
        },
        companyName: {
            label: 'companyName',
            data: [
                {type: 'text', val: 'company'},
                {type: 'prop', val: 'companyId'}
            ]
        },
        kioskId: {
            label: 'kioskId',
            data: [
                {type: 'text', val: 'device'},
                {type: 'prop', val: 'kioskId'}
            ]
        }
    },

    subtotals: {
        groupBy: 'locationName',
        fields: {
            locationName: 'header',
            startCashOnHand: 'total',
            endCashOnHand: 'total'
        },
        formats: {
            startCashOnHand: 'currency-decimal',
            endCashOnHand: 'currency-decimal'
        }
    }
}
