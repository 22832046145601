import './pullTabs.scss';
import { useEffect, useRef, useState } from 'react';
import { pullTabsLayout as layout } from './pullTabsLayout';
import { getDefaultDateRange } from 'components/shared/componentUtils';
import { pullTabsParamMap } from 'shared/utils';
import { vendorsList } from 'shared/data';
import { text as t } from 'shared/text';
import useAuth from 'hooks/Auth/useAuth';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/Profile/useProfile';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import Dropdown from 'components/Dropdown/Dropdown';
import Page from 'app-components/Page/Page';
import TablePaged from 'components/Tables/TablePaged';

const PullTabs = () => {
    const { getAuth } = useAuth();
    const { inputValues, updateNestedInputData } = useInputs();
    const { setHeader } = useProfile();
    const { updateTable } = useTables();
    const { callAPI, callAPIGet, updateNotice } = useUtils();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ csvFile, setCsvFile ] = useState(null);
    const [ fileState, setFileState ] = useState('placeholder');
    const [ fileContent, setFileContent ] = useState(t.noFileSelected);
    const runOnce = useRef(false);
    const reportProps = useRef({});

    const tableId = 'pull-tabs-batch';

    layout.navigation.resendEmail.onClick = (pageId, rowData) => {
        const params = {batchId: rowData.id, identityUserId: 0};
        callAPI('pullTabsEmail', pullTabsEmailCallback, params )
    }

    useEffect(() => {   
        if (runOnce.current) return;
        runOnce.current = true; 
        setHeader(t.pullTabs);
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        updateNestedInputData({selectedVendor: 'PTW'}, {selectedVendor: {list:0}}, [vendorsList]);
    }, []);

    const getReportsData = (data, callback, resetPage) => {
        const params = {
            CompanyId: getAuth().companyId,
            Search: data.searchTerm,
            PageIndex: data.currentPage,
            PageSize: data.rowCount,
            OrderBy: data.orderBy,
            Desc: data?.desc,
        }
        if (data.periodStart) {
            params.PeriodStart = data.periodStart;
            params.PeriodEnd = data.periodEnd;
        }
        data.callback = callback;
        data.resetPage = resetPage;
        reportProps.current.params = pullTabsParamMap(params);
        reportProps.current.callback = callback;
        callAPIGet('pullTabsBatchGet', pullTabsBatchGetCallback, params, data);
    }

    const pullTabsBatchGetCallback = (data, props) => {
        if (props.callback) {
            props.records = data?.data || [];
            props.pageCount = data?.pageCount || 0;
            props.callback(props, props.resetPage);
        }
    }

    const pullTabsEmailCallback = (data) => {
        updateNotice(data.isSuccessful);
    }

    const onChooseClick = () => {
        document.getElementById('csv-input').click();
    }

    const changeFile = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.name.endsWith('.csv') || file.type === 'text/csv') {
                setCsvFile(file);
                setFileState('csv');
                setFileContent(file.name);
            } else {
                setFileState('error');
                setFileContent(t.errorInvalidCSV)
            }
        }
    };

    const onEncryptClick = (setLoading) => {
        if (!csvFile) {return};
        setLoading(true);
        const formData = new FormData();
        formData.append('InputFile', csvFile); 
        formData.append('Vendor', inputValues.selectedVendor);
        formData.append('CompanyId', getAuth().companyId);
        const props = {
            type: 'FormData',
            setLoading: setLoading
        }
        callAPI('pullTabsUpload', uploadCSVCallback, formData, props);
    }

    const uploadCSVCallback = (data, props) => {
        props.setLoading(false);
        if (data.isSuccessful) {
            updateNotice({isSuccessful: true});
            getReportsData(reportProps.current.params, reportProps.current.callback, true);
            setFileState('placeholder');
            setFileContent(t.noFileSelected);
            document.getElementById('csv-input').value = '';
        } else {
            updateNotice(false);
        }
    }

    const onDateRangePick = (range) => {
        updateTable('selectedRows', tableId, null);
        setTimeout(() => {
            updateTable('dates', tableId, range);
        }, 0)
    }

    return (
        <Page
            page={t.pullTabs}
            contentClasses='grid'
        >
            <div className='csv-container half'>
                <div className='pull-tabs-label'>{t.uploadCSV}:</div>
                <div className='row grid'>
                    <Button 
                        classes='third'
                        onClick={onChooseClick}
                    >{t.chooseCSV}</Button>
                    <div className={`csv-file-display two-thirds ${fileState}`}>{fileContent}</div> 
                    <input id='csv-input' type='file' accept='.csv' onChange={changeFile}/>
                </div>
            </div>
            <div className='twelfth'/>
            <Dropdown
                id='selectedVendor'
                classes='quarter'
                label={t.selectVendor}
                data={vendorsList}
            />
            <Button 
                classes='pull-tabs-submit green sixth'
                type='submit'
                disabled={fileState !== 'csv'}
                onClick={(event, setLoading) => {onEncryptClick(setLoading)}}
            >{t.encryptAndEmail}</Button>
            <TablePaged
                id={tableId}
                layout={layout}
                classes='full'
                defaultDates={defaultDates}
                apiId='pullTabsBatchGet'
                apiCall={getReportsData}
                searchBarContent={
                    <DateRangePicker
                        classes='quarter'
                        defaultVal={defaultDates}
                        label={t.dateRange}
                        stateId={tableId}
                        callback={onDateRangePick}
                    />
                }
            />
        </Page>
    )
}

export default PullTabs;
