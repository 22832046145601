import './cashDisplay.scss'
import { toCurrency } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';

const CashDisplay = ({data, machine}) => {
    const slots = data?.cassettes ? data.cassettes : [];
    return (
        <div className='cash-display-container grid half'>
            <div className='cash-display-label full'>{machine === 'recycler' ? t.recycler : t.cassettes}</div>
            <div className={`cash-display full`}>
                {slots.map((slot, i)=> {
                    const count = slot.current_Level;
                    const max = machine === 'recycler' ? 100 : slot.standard_Fill;
                    const value = slot.denom;
                    return (<div key={i} className={`cash-display-column`}>
                        <div className='cash-display-denom'>{toCurrency(value)}</div>
                        <div className='cash-display-bar-container'>
                            <div className='cash-display-bar-display'>{toCurrency(value * count)}</div>
                            <div className='cash-display-bar' style={{height: `${Math.min(count / max, 1) * 100}%`}}></div>
                        </div>
                        <div className='cash-display-details'>
                            <div className='cash-display-values'>{`${count} / ${max}`}</div>
                        </div>
                    </div>)
                })}
            </div>
        </div> 
    )
}

export default CashDisplay;
