import { text as t } from 'shared/text'

export const manifestsLayouts = {
    id: 'manifests',

    tableHeaders: {
        id: t.edit,
        terminalId: t.terminalId,
        locationId: t.locationId,
        userId: t.userId,
        companyId: t.companyId,
        terminalType: t.terminalType,
        terminalSubClass: t. terminalSubtype,
        targetVersion: t.version,
        targetDateTime: t.targetDateTime,
        updateSource: t.source,
        updateSourceType: t.sourceTypes,
        isNewInstall: t.isNewInstall,
    },

    sizes: {
        id: 'icon',
        terminalId: 'mid',
        locationId: 'mid',
        userId: 'mid',
        companyId: 'mid',
        terminalType: 'mid',
        terminalSubClass: 'mid',
        targetVersion:'mid',
        targetDateTime: 'large',
        updateSource: 'mid',
        updateSourceType: 'mid',
        isNewInstall: 'large'
    },

    layouts: {
        companyId: 'text-left',
        terminalType: 'text-left',
        terminalSubClass: 'text-left',
        targetVersion: t.version,
        targetDateTime: 'text-left',
        updateSource: 'text-left' 
    },

    formats: {
        targetDateTime: 'date-standard'
    },

    navigation: {
        id: {
            icon: 'edit',
            data: [
                {type: 'text', val: 'manifest'},
                {type: 'prop', val: 'id'},
            ]
        },
        userId: {
            label: 'userId',
            data: [
                {type: 'text', val: 'user'},
                {type: 'prop', val: 'userId'},
            ]
        },
        locationId: {
            label: 'locationId',
            data: [
                {type: 'text', val: 'location'},
                {type: 'prop', val: 'locationId'},
            ]
        },
        terminalId: {
            label: 'terminalId',
            data: [
                {type: 'text', val: 'device'},
                {type: 'prop', val: 'terminalId'},
            ]
        },
        updateSource: {
            label: 'updateSource',
            data: [
                {type: 'text', val: 'source'},
                {type: 'prop', val: 'updateSourceId'},
            ]
        }
    }
}
