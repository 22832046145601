import { text as t } from 'shared/text'

export const companyPerformanceReportsLayout = {    
    hasDateRange: true,

    defaultSort: {
        orderBy: 'netRevenue',
        desc: true,
    },

    tableHeaders: {
        companyName: t.companyName,
        cashIn: t.cashIn,
        cashOut: t.cashOut,
        promo: t.promo,
        netRevenue: t.netRevenue
    },

    sizes: {
        companyName: 'large',
        cashIn: 'mid',
        cashOut: 'mid',
        promo: 'mid',
        netRevenue: 'mid',
    },

    layouts: {
        companyName: 'text-left',
        cashIn: 'text-right',
        cashOut: 'text-right',
        promo: 'text-right',
        netRevenue: 'text-right',
    },

    formats: {
        cashIn: 'currency',
        cashOut: 'currency',
        promo: 'currency',
        netRevenue: 'currency',
    },

    navigation: {
        companyName: {
            label: 'companyName',
            data: [
                {type: 'text', val: 'company'},
                {type: 'prop', val: 'companyId'},
            ]
        }
    },

    // subtotals: {
    //     groupBy: 'all',
    //     fields: {
    //         companyName: 'header',
    //         cashIn: 'total',
    //         cashOut: 'total',
    //         promo: 'total',
    //         netRevenue:'total',
    //     }, 
    //     formats: {
    //         cashIn: 'currency',
    //         cashOut: 'currency',
    //         promo: 'currency',
    //         netRevenue:'currency',
    //     }
    // }
}
